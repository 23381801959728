import { ReactNode } from 'react'
// guards
// components
import MainLayout from './main'
import DashboardLayout from './dashboard'
import LogoOnlyLayout from './LogoOnlyLayout'
import AdminLayout from './admin'

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode
  variant?: 'main' | 'dashboard' | 'logoOnly' | 'admin'
}

export default function Layout({ variant = 'dashboard', children }: Props) {
  if (variant === 'logoOnly') {
    return <LogoOnlyLayout> {children} </LogoOnlyLayout>
  }

  if (variant === 'main') {
    return <MainLayout>{children}</MainLayout>
  }
  if (variant === 'admin') {
    return <AdminLayout>{children}</AdminLayout>
  }

  return <DashboardLayout> {children} </DashboardLayout>
}
