import { useState, ReactNode } from 'react'
// @mui
import { styled } from '@mui/material/styles'
import { Box, Button, Typography } from '@mui/material'
// hooks
import useSettings from '@/hooks/useSettings'
import useResponsive from '@/hooks/useResponsive'
import useCollapseDrawer from '@/hooks/useCollapseDrawer'
import useAuth from '@/hooks/useAuth'
import useApp from '@/hooks/useApp'
// config
import { HEADER, NAVBAR } from '../../config'
//
import DashboardHeader from './header'
import NavbarVertical from './navbar/NavbarVertical'
import NavbarHorizontal from './navbar/NavbarHorizontal'
import LoadingScreen from '@/components/LoadingScreen'
import { ownerEmails } from '@/appConfig'
import Logo from '@/components/Logo'

// ----------------------------------------------------------------------

type MainStyleProps = {
  collapseClick: boolean
}

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})<MainStyleProps>(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}))

const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  width: '100%',
}))

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode
}

export default function AdminLayout({ children }: Props) {
  const { error, isLoading, user, login } = useAuth()
  const { companyColor } = useApp()
  const { collapseClick, isCollapse } = useCollapseDrawer()
  const { themeLayout } = useSettings()
  const isDesktop = useResponsive('up', 'lg')
  const [open, setOpen] = useState(false)
  const verticalLayout = themeLayout === 'vertical'

  if (isLoading) return <LoadingScreen companyColor={companyColor} />
  if (error)
    return (
      <StyledContainer>
        <Typography variant="body2" sx={{ color: 'error.main' }}>
          {error.message}
        </Typography>
      </StyledContainer>
    )

  if (!user) {
    return (
      <StyledContainer>
        <Logo disabledLink sx={{ width: 250, height: 250 }} />
        <Button variant="contained" size="large" onClick={() => login()}>
          Login
        </Button>
      </StyledContainer>
    )
  }

  if (!ownerEmails.includes(user.email ?? '')) {
    return (
      <StyledContainer>
        <Typography variant="h4" mb={2} sx={{ color: 'error.main' }}>
          You are not allowed to enter the admin page
        </Typography>
        <Button variant="contained" size="large" href="/">
          Go Home
        </Button>
      </StyledContainer>
    )
  }
  if (verticalLayout) {
    return (
      <>
        <DashboardHeader onOpenSidebar={() => setOpen(true)} verticalLayout={verticalLayout} />

        {isDesktop ? (
          <NavbarHorizontal />
        ) : (
          <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
        )}

        <Box
          component="main"
          sx={{
            px: { lg: 2 },
            pt: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
            },
            pb: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
            },
          }}
        >
          {children}
        </Box>
      </>
    )
  }

  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
      }}
    >
      <DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} />

      <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

      <MainStyle collapseClick={collapseClick}>{children}</MainStyle>
    </Box>
  )
}
